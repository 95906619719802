import React from "react";

// Components
import { graphql } from "gatsby";
import Image from "gatsby-image";
import SEO from "../shared/SEO";

// Hooks
import useWindowSize from "../../helpers/useWindowSize";

// Style
import styled, { css } from "styled-components";
import {
  neuzeitGroLight,
  primary,
  mediaQueries,
  fontSize
} from "../../styles/fonts";

const Text = styled.div`
  ${neuzeitGroLight}
  font-size: ${fontSize.xl};
  color: white;

  @media (max-width: ${mediaQueries.mobile}) {
    font-size: ${fontSize.lg};
  }

  @media (max-height: ${mediaQueries.mobile}) {
    font-size: ${fontSize.lg};
  }

`;

const OrangeText = styled.span`
  ${neuzeitGroLight}
  font-size: ${fontSize.xl};
  color: ${primary};

  @media (max-width: ${mediaQueries.mobile}) {
    font-size: ${fontSize.lg};
  }

  @media (max-height: ${mediaQueries.mobile}) {
    font-size: ${fontSize.lg};
  }

`;

const OrangeHoverText = styled.span`
  ${neuzeitGroLight}
  font-size: ${fontSize.xl};
  color: white;
  cursor: pointer;

  &:hover {
    color: ${primary};
  }

  @media (max-width: ${mediaQueries.mobile}) {
    font-size: ${fontSize.lg};
  }

  @media (max-height: ${mediaQueries.mobile}) {
    font-size: ${fontSize.lg};
  }
`;

const TextContainer = styled.div`
  text-align: center;
  overflow: scroll;
  z-index: 1;

  ${props =>
    props.aspectRatio &&
    props.height &&
    props.width &&
    css`
      width: calc((${props.height}px - 64px) * ${props.aspectRatio});
      @media (max-width: ${mediaQueries.tablet}) {
        width: calc((${props.height}px - 96px) * ${props.aspectRatio});
      }

      @media (max-width: ${mediaQueries.mobile}) {
        width: calc(${props.width}px - 64px);
      }
    `}
`;

const TextBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 20px;

  ${props =>
    props.height &&
    css`
      height: ${props.height}px;
    `}
`;

const TopTextBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -32px;
  padding: 0px 20px;

  @media (max-width: ${mediaQueries.tablet}) {
    margin-top: -64px;
  }

  ${props =>
    props.height &&
    css`
      height: ${props.height}px;
    `}
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const ImageContainer = styled.div`
  overflow: scroll;
  position: fixed;

  ${props =>
    props.aspectRatio &&
    props.height &&
    props.width &&
    css`
      width: calc((${props.height}px - 64px) * ${props.aspectRatio});
      height: calc(${props.height}px - 64px);

      @media (max-width: ${mediaQueries.tablet}) {
        width: calc((${props.height}px - 96px) * ${props.aspectRatio});
        height: calc(${props.height}px - 96px);
        left: 50%;
        transform: translateX(-50%);
      }

      @media (max-width: ${mediaQueries.mobile}) {
        height: calc((${props.width}px - 64px) / ${props.aspectRatio});
        width: calc(${props.width}px - 64px);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    `}
`;

const linkStyle = {
  textDecoration: "none"
};

const About = ({ data }) => {
  const imageInfo = data.fileName.childImageSharp.fluid;
  const size = useWindowSize();

  return (
    <Container>
      <SEO content={{ title: "ABOUT", excerpt: "About NPRZ.IO" }} />
      <TextContainer
        aspectRatio={imageInfo.aspectRatio}
        height={size.height}
        width={size.width}
      >
        <TopTextBlock height={size.height}>
          <Text>
            Hi, my name is <OrangeText>Nick</OrangeText>.
          </Text>
        </TopTextBlock>
        <TextBlock height={size.height}>
          <Text>
            I am a <OrangeText>Software Engineer</OrangeText> based out of
            Detroit.
          </Text>
        </TextBlock>
        <TextBlock height={size.height}>
          <Text>
            I specialize in <OrangeText>Front End</OrangeText> Web Development.
          </Text>
        </TextBlock>
        <TextBlock height={size.height}>
          <Text>
            Contact:
            <a
              href="https://github.com/nprz"
              target="_blank"
              rel="noopener noreferrer"
              style={linkStyle}
            >
              <OrangeHoverText> github </OrangeHoverText>
            </a>
            <a
              href="mailto:nick@nprz.io"
              target="_blank"
              rel="noopener noreferrer"
              style={linkStyle}
            >
              <OrangeHoverText>email </OrangeHoverText>
            </a>
            <a
              href="https://twitter.com/__nprz"
              target="_blank"
              rel="noopener noreferrer"
              style={linkStyle}
            >
              <OrangeHoverText>twitter </OrangeHoverText>
            </a>
          </Text>
        </TextBlock>
      </TextContainer>

      <ImageContainer
        aspectRatio={imageInfo.aspectRatio}
        height={size.height}
        width={size.width}
      >
        <Image title="sky?" alt="blue sky and building" fluid={imageInfo} />
      </ImageContainer>
    </Container>
  );
};

export default About;

export const query = graphql`
  query {
    fileName: file(relativePath: { eq: "sky.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
